import React from "react";

const Pacareerlink = () => {
  return (
    <>
      <main>
        <section>
          <h1>Are you looking for work in PA Six?</h1>

          <p>
            PA CareerLink® centers provide one-stop delivery of career and
            workforce services to job seekers and employers, PA CareerLink®
            centers have an experienced team available to assist you in every
            aspect of your career search. PA CareerLink® centers also have an
            experienced business service team available to assist employers in
            recruiting and retaining a skilled workforce.
          </p>

          <p>
            Services available to job seekers at each of our PA CareerLink®
            centers include:
          </p>

          <ul>
            <li>
              Resource rooms with phones, free internet and resume writing tools
            </li>
            <li>Employment plan development</li>
            <li>Job training services</li>
            <li>Job search assistance</li>
            <li>Career counseling</li>
            <li>Practice interviewing</li>
            <li>Skills testing</li>
            <li>Labor market and employer information</li>
            <li>Employment workshops</li>
            <li>
              Supportive services (which can include information about SNAP,
              financial assistance, Medicaid, training services, child care,
              emergency funds, and other benefits)
            </li>
            <li>Hiring events and business service information</li>
            <li>
              Accessibility and special accommodations for people with
              disabilities
            </li>
            <li>
              Referrals to community resources and other agencies, and more
            </li>
          </ul>

          <h2>HELP IN FINDING THE PERFECT JOB IS JUST A CLICK AWAY!</h2>

          <p>
            Visit PA CareerLink® for their on-line job matching to hundreds of
            jobs in PA Six.
            <br />
            https://www.pacareerlink.pa.gov/jponline/individual
          </p>

          <h2>OUR SERVICES ARE FREE</h2>

          <p>
            Visit our Welcome Video  (link to video on Ready! Set! Work!
            Website) to learn how we can help you find a job through our
            employment and training services. We have a variety of free Services
            that are available to all job seekers in the PA Six, and we can also
            help you with your online job search!
          </p>
          <p>
            In addition, you may qualify for our Intensive Services. These
            services provide customized job search assistance, training services
            such as classroom and on-the-job training (OJT), and more. Through
            Intensive Services you can also meet with one of our Team Members,
            who will help you develop an Individualized Employment Plan and
            assist you with your job search.
          </p>

          <p>
            We hope you will take advantage of our employment resources online,
            but remember that a caring staff member is only a phone call away.
            Additionally, our Career Resource Centers are equipped with all the
            resources you need to begin to your job search!
          </p>
          <p>
            Our PA CareerLink® centers are funded primarily by the Pennsylvania
            Department of Labor and Industry and are certified by the North
            Central Workforce Development Board.
          </p>

          <h2>FIND SKILLED WORKERS AT PA SIX THROUGH PA CAREERLINK®</h2>

          <p>
            At PA CareerLink® centers our main business is to provide you with
            skilled workers. We promise that the employment, training, and labor
            market services you receive through our workforce delivery system
            are professional, comprehensive, and on-target.
          </p>
          <p>Best of all, all of our services are at no cost to you!</p>
          <p>View our employer services packet to learn more.</p>

          <p>
            Services available to employers at each of our PA CareerLink®
            centers include:
          </p>
          <ul>
            <li>Search for Candidates</li>
            <li>On-the-Job Training</li>
            <li>Job Fairs</li>
            <li>Virtual Interviews</li>
            <li>Learn about Disability Service Partnerships</li>
            <li>Learn more about Apprenticeships</li>
            <li>Report a New Hire</li>
            <li>Job Market Data</li>
            <li>
              Unemployment Compensation Benefits and Unemployed Compensation Tax
            </li>
            <li>Apprenticeship Opportunities</li>
            <li>Workforce and Compliance-Related Resources</li>
            <li>Work Opportunity Tax Credit (WOTC) Program</li>
            <li>Federal Bonding</li>
            <li>Incumbent Worker Training</li>
            <li>Sector Partnerships</li>
          </ul>

          <p>
            Visit PA CareerLink® for their on-line job matching tool:
            https://www.pacareerlink.pa.gov/jponline/Common/LandingPage/Employer
          </p>
        </section>
      </main>
    </>
  );
};

export default Pacareerlink;
